<template>
  <div>
    <table class="packages-table responsive-table">
      <thead>
        <tr>
          <td>{{ $t('package.listTable.id') }}</td>
          <td>{{ $t('package.listTable.name') }}</td>
          <td>{{ $t('package.listTable.program_key') }}</td>
          <td>{{ $t('package.listTable.card_product_id') }}</td>
          <td>{{ $t('package.listTable.card_design') }}</td>
          <td>{{ $t('package.listTable.logo_front_id') }}</td>
          <td>{{ $t('package.listTable.logo_back_id') }}</td>
          <td>{{ $t('package.listTable.product_ref') }}</td>
          <td>{{ $t('package.listTable.carrier_type') }}</td>
          <td>{{ $t('package.listTable.status.title') }}</td>
          <td>{{ $t('package.listTable.bulk') }}</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td class="packages-table--id">
            <h5>{{ $t('package.listTable.id') }}:</h5>
            <span :class="{ 'info-not-available': !item.id }">{{ item.id | orNone }}</span>
          </td>
          <td class="packages-table--name">
            <h5>{{ $t('package.listTable.name') }}:</h5>
            <span :class="{ 'info-not-available': !item.name }">{{ item.name | orNone }}</span>
          </td>
          <td class="packages-table--program">
            <h5>{{ $t('package.listTable.program_key') }}:</h5>
            <span :class="{ 'info-not-available': !item.card_program_key }">{{ item.card_program_key + ' (' + (item.card_program_type || '?') + ')' }}</span>
          </td>
          <td class="packages-table--card_product_id">
            <h5>{{ $t('package.listTable.card_product_id') }}:</h5>
            <span :class="{ 'info-not-available': !item.card_product_id }">{{ item.card_product_id | orNone }}</span>
          </td>
          <td class="packages-table--card_design">
            <h5>{{ $t('package.listTable.card_design') }}:</h5>
            <span :class="{ 'info-not-available': !item.gps.card_design }">{{ item.gps.card_design | orNone }}</span>
          </td>
          <td class="packages-table--logo_front_id">
            <h5>{{ $t('package.listTable.logo_front_id') }}:</h5>
            <span :class="{ 'info-not-available': !item.gps.logo_front_id }">{{ item.gps.logo_front_id | orNone }}</span>
          </td>
          <td class="packages-table--logo_back_id">
            <h5>{{ $t('package.listTable.logo_back_id') }}:</h5>
            <span :class="{ 'info-not-available': !item.gps.logo_back_id }">{{ item.gps.logo_back_id | orNone }}</span>
          </td>
          <td class="packages-table--product_ref">
            <h5>{{ $t('package.listTable.product_ref') }}:</h5>
            <span :class="{ 'info-not-available': !item.gps.product_ref }">{{ item.gps.product_ref | orNone }}</span>
          </td>
          <td class="packages-table--carrier_type">
            <h5>{{ $t('package.listTable.carrier_type') }}:</h5>
            <span :class="{ 'info-not-available': !item.gps.carrier_type }">{{ item.gps.carrier_type | orNone }}</span>
          </td>
          <td class="packages-table--status">
            <h5>{{ $t('package.listTable.status') }}:</h5>
            <span :class="{ 'info-not-available': !item.status, [`card-package-status-${item.status}`]: true }">{{ $t(`package.listTable.status.values.${item.status}`) | orNone }}</span>
          </td>
          <td class="packages-table--bulk">
            <h5>{{ $t('package.listTable.bulk') }}:</h5>
            <span>{{ item.bulk }}</span>
          </td>
          <td v-if="isItemCanBeActivated(item)">
            <v-btn align-bottom small outline color="primary" @click="showModalActivateCardPackage(item)">{{ $t(`package.activate_action.title`) }}</v-btn>
          </td>
          <td v-if="isItemCanBeDeactivated(item)">
            <v-btn align-bottom small outline color="error" @click="showModalDeactivateCardPackage(item)">{{ $t(`package.listTable.deactivate`) }}</v-btn>
          </td>
          <td class="packages-table--actions">
            <v-btn
              align-bottom
              small
              outline
              color="primary"
              @click="
                passPackageToModal(item);
                showModalUpdatePackage();
              "
              class="packages-table--update-package"
              >{{ $t(`package.listTable.edit`) }}</v-btn
            >
          </td>
        </tr>
      </tbody>
      <modal-action :show-modal="showUpdatePackageModal">
        <update-package-action :package="currentPackage" @close="closeModalUpdatePackage" @action="reloadPage" />
      </modal-action>
      <modal-confirm
        :show-modal="showDeletePackageModal"
        :title="$t('package.delete_package_action.title')"
        context="DeletePackageConfirmationModal"
        @submit="deletePackage()"
        @close="closeModalDeletePackage"
        :text="$t('package.delete_package_action.text')"
        cancel_text="package.delete_package_action.button_cancel"
        submit_text="package.delete_package_action.button_save"
        :submit_working="working"
      ></modal-confirm>

      <modal-confirm
        :show-modal="showDeactivateCardPackage"
        :title="$t('package.deactivate_action.title')"
        context="DeactivateCardPackageConfirmationModal"
        @submit="deactivateCardPackage()"
        @close="closeModalDeactivateCardPackage"
        :text="$t('package.deactivate_action.text')"
        cancel_text="package.deactivate_action.button_cancel"
        submit_text="package.deactivate_action.button_save"
        :submit_working="working"
      ></modal-confirm>

      <modal-confirm
        :show-modal="showActivateCardPackage"
        :title="$t('package.activate_action.title')"
        context="ActivateCardPackageConfirmationModal"
        @submit="activateCardPackage()"
        @close="closeModalActivateCardPackage"
        :text="$t('package.activate_action.text')"
        cancel_text="package.activate_action.button_cancel"
        submit_text="package.activate_action.button_save"
        :submit_working="working"
      ></modal-confirm>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="pageCount > 1" class="packages-table--pagination" v-model="pagination" :length="pageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import UpdatePackageAction from './UpdatePackageAction';

export default {
  name: 'packages-table',
  mixins: [actionErrorTrackable, security],
  components: { UpdatePackageAction },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    const $t = this.$t.bind(this);
    return {
      currentCardPackage: {},
      pagination: 1,
      packageKey: '',
      currentPackage: {},
      showUpdatePackageModal: false,
      showDeletePackageModal: false,
      showDeactivateCardPackage: false,
      showActivateCardPackage: false,
      actions: [
        {
          slotName: 'deactivateCardPackage',
          name: $t(`package.listTable.deactivate`),
          action: (item) => this.showModalDeactivateCardPackage(item),
          isAvailable: (item) => item.status !== 'deactivated',
        },
        {
          slotName: 'activateCardPackage',
          name: $t(`package.listTable.activate`),
          action: (item) => this.showModalActivateCardPackage(item),
          isAvailable: (item) => item.status !== 'active',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('package', ['pageCount', 'pageNumber', 'listStatus']),
  },
  watch: {
    pageNumber() {
      this.pagination = this.pageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'package/changePage',
          success: this.success,
        },
        {
          page: newValue,
        },
      );
    },
  },
  methods: {
    reloadPage() {
      this.packageKey = '';
      this.currentPackage = {};
      this.showUpdatePackageModal = false;
      this.showDeletePackageModal = false;
      this.showDeactivateCardPackage = false;
      this.showActivateCardPackage = false;
    },
    passPackageToModal(cardPackage) {
      this.currentPackage = cardPackage;
    },
    passPackageKeyToModal(packageKey) {
      this.packageKey = packageKey;
    },
    async deletePackage() {
      await this.executeAction(
        {
          action: 'package/deletePackage',
          success: this.deletePackageSuccess,
        },
        this.packageKey,
      );
    },
    deletePackageSuccess() {
      this.reloadPage();
    },
    showModalUpdatePackage() {
      this.showUpdatePackageModal = true;
    },
    closeModalUpdatePackage() {
      this.showUpdatePackageModal = false;
    },
    showModalDeletePackage() {
      this.showDeletePackageModal = true;
    },
    closeModalDeletePackage() {
      this.showDeletePackageModal = false;
    },

    isItemCanBeActivated(item) {
      return item.status !== 'active';
    },

    showModalActivateCardPackage(cardPackage) {
      this.currentCardPackage = cardPackage;
      this.showActivateCardPackage = true;
    },

    async activateCardPackage() {
      await this.executeAction(
        {
          action: 'package/activatePackage',
          success: this.activateCardPackageSuccess,
        },
        this.currentCardPackage.key,
      );
    },

    activateCardPackageSuccess() {
      this.reloadPage();
    },

    closeModalActivateCardPackage() {
      this.showActivateCardPackage = false;
    },

    isItemCanBeDeactivated(item) {
      return item.status !== 'deactivated';
    },

    async deactivateCardPackage() {
      await this.executeAction(
        {
          action: 'package/deactivatePackage',
          success: this.deactivateCardPackageSuccess,
        },
        this.currentCardPackage.key,
      );
    },
    closeModalDeactivateCardPackage() {
      this.showDeactivateCardPackage = false;
    },

    showModalDeactivateCardPackage(cardPackage) {
      this.currentCardPackage = cardPackage;
      this.showDeactivateCardPackage = true;
    },

    deactivateCardPackageSuccess() {
      this.reloadPage();
    },
  },
};
</script>
