import Vue from 'vue';

const service = {
  async listPackages({ page, limit, status, keyword, pack, logo, bulk }) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}${generateListPackagesPath({ page, limit, status, keyword, pack, logo, bulk })}`);
    return response.data.data;
  },

  async createPackage(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/card-packages/v1`, payload);
    return response.data.data;
  },

  async updatePackage(key, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/card-packages/v1/${key}`, payload);
    return response.data.data;
  },

  async deletePackage(key) {
    const response = await Vue.prototype.$axios.delete(`${process.env.VUE_APP_BASE_API_URL}/card-packages/v1/${key}`);
    return response.data;
  },

  async deactivatePackage(key) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/card-packages/v1/${key}/deactivate`, {});
    return response.data;
  },

  async activatePackage(key) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/card-packages/v1/${key}/activate`, {});
    return response.data;
  },
};

function generateListPackagesPath({ page, limit, status, keyword, pack, logo, bulk } = {}) {
  let path = '/card-packages/v1';
  if (page || limit || status || keyword || pack || logo || bulk) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }
  if (status) {
    queryStrings.push(['status', status]);
  }
  if (keyword) {
    queryStrings.push(['keyword', keyword]);
  }
  if (pack) {
    queryStrings.push(['package', pack]);
  }
  if (logo) {
    queryStrings.push(['logo', logo]);
  }
  if (bulk) {
    queryStrings.push(['bulk', bulk]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
