import Vue from 'vue';
import { safeDispatcher } from '../../utils/context/context-helper';
import errorTypes from '@/utils/error/error-types';

const defaultPage = 1;
let paginationLimit = 20;

export default {
  namespaced: true,
  state: {
    packages: [],
    pageNumber: defaultPage,
    pageCount: 0,
    listStatus: '',
    listKeyword: '',
    listPackage: '',
    listLogo: '',
    listBulk: '',
  },

  getters: {
    packagesList: (state) => state.packages,
    pageNumber: (state) => state.pageNumber,
    pageCount: (state) => state.pageCount,
    listStatus: (state) => state.listStatus,
    listKeyword: (state) => state.listKeyword,
    listPackage: (state) => state.listPackage,
    listLogo: (state) => state.listLogo,
    listBulk: (state) => state.listBulk,
    packageByKey: (state) => (key) => {
      return state.packages.find((cardPackage) => cardPackage.key === key);
    },
  },

  mutations: {
    packages(state, packagesList) {
      state.packages = packagesList;
    },
    pageCount(state, pageCount) {
      state.pageCount = pageCount;
    },
    listStatus(state, status) {
      state.listStatus = status;
    },
    listKeyword(state, keyword) {
      state.listKeyword = keyword;
    },
    listPackage(state, pack) {
      state.listPackage = pack;
    },
    listLogo(state, logo) {
      state.listLogo = logo;
    },
    listBulk(state, bulk) {
      state.listBulk = status;
    },
    page(state, page) {
      state.pageNumber = page;
    },
  },

  actions: {
    async listPackages({ commit, state }, { page, limit, status, keyword, pack, logo, bulk }) {
      const packageService = Vue.prototype.$services.cardPackage;
      const thePage = page || defaultPage;
      const theLimit = limit || paginationLimit;
      const theStatus = _.isUndefined(status) ? state.listStatus : status;
      const theKeyword = _.isUndefined(keyword) ? state.listKeyword : keyword;
      const thePackage = _.isUndefined(pack) ? state.listPackage : pack;
      const theLogo = _.isUndefined(logo) ? state.listLogo : logo;
      const theBulk = _.isUndefined(bulk) ? state.listBulk : bulk;

      const params = {
        page: thePage,
        limit: theLimit,
        status: theStatus,
        keyword: theKeyword,
        pack: thePackage,
        logo: theLogo,
        bulk: theBulk,
      };
      const packagesList = await packageService.listPackages(params);

      commit('page', thePage);
      commit('pageCount', packagesList.page_count);
      commit('packages', packagesList.items);
      commit('listStatus', theStatus);
      commit('listKeyword', theKeyword);
      commit('listPackage', thePackage);
      commit('listLogo', theLogo);
      commit('listBulk', theBulk);
    },

    async changePage({ dispatch }, { page }) {
      const defaultDispatch = safeDispatcher(dispatch);
      await defaultDispatch('package/listPackages', { page });
    },

    async createPackage({ dispatch, state }, payload) {
      const packageService = Vue.prototype.$services.cardPackage;
      const defaultDispatch = safeDispatcher(dispatch);

      await packageService.createPackage(payload);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'package.add_package_action.success' });
      await defaultDispatch('package/listPackages', { page: state.pageNumber, status: state.listStatus });
    },

    async updatePackage({ dispatch, state }, { key, cardPackage }) {
      const packageService = Vue.prototype.$services.cardPackage;
      const defaultDispatch = safeDispatcher(dispatch);

      const payload = {
        id: cardPackage.id,
        name: cardPackage.name,
        card_program_key: cardPackage.card_program_key,
        card_product_id: cardPackage.card_product_id,
        gps: {
          logo_front_id: cardPackage.gps.logo_front_id,
          logo_back_id: cardPackage.gps.logo_back_id,
          card_design: cardPackage.gps.card_design,
          product_ref: cardPackage.gps.product_ref,
          carrier_type: cardPackage.gps.carrier_type,
        },
        bulk: cardPackage.bulk,
      };

      await packageService.updatePackage(key, payload);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'package.update_package_action.success' });

      await defaultDispatch('package/listPackages', { page: state.pageNumber, status: state.listStatus });
    },

    async deactivatePackage({ dispatch, state }, key) {
      const service = Vue.prototype.$services.cardPackage;
      const defaultDispatch = safeDispatcher(dispatch);

      await service.deactivatePackage(key);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'package.deactivate_action.success' });
      await defaultDispatch('package/listPackages', { page: state.pageNumber, status: state.listStatus });
    },

    async activatePackage({ dispatch, state }, key) {
      const service = Vue.prototype.$services.cardPackage;
      const defaultDispatch = safeDispatcher(dispatch);

      await service.activatePackage(key);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'package.activate_action.success' });
      await defaultDispatch('package/listPackages', { page: state.pageNumber, status: state.listStatus });
    },

    async deletePackage({ dispatch, state }, key) {
      const packageService = Vue.prototype.$services.cardPackage;
      const defaultDispatch = safeDispatcher(dispatch);

      const res = await packageService.deletePackage(key);

      if (res.result === 'fail') {
        res.error.code = 'package.deletion';
        throw new errorTypes.HttpError('fail', res);
      }

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'package.delete_package_action.success' });

      await defaultDispatch('package/listPackages', { page: state.pageNumber, status: state.listStatus });
    },
  },
};
