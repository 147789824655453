<template>
  <v-flex>
    <h1 hidden>{{ $t('package.list.title') }}</h1>
    <v-card class="form-basic pa-2">
      <v-layout row wrap align-center justify-space-between>
        <v-flex>
          <form @submit.prevent="submit" class="form-search">
            <v-layout class="gap" row wrap align-center>
              <v-flex>
                <v-text-field name="keyword" v-model="keyword" :error-messages="errors.collect('keyword')" data-vv-name="keyword" clearable :label="$t('package.list.filters.keyword')" maxlength="50" />
              </v-flex>
              <v-flex>
                <v-text-field name="pack" v-model="pack" :error-messages="errors.collect('pack')" data-vv-name="pack" clearable :label="$t('package.list.filters.pack')" maxlength="50" />
              </v-flex>
              <v-flex>
                <v-text-field name="logo" v-model="logo" :error-messages="errors.collect('logo')" data-vv-name="logo" clearable :label="$t('package.list.filters.logo')" maxlength="50" />
              </v-flex>
              <v-flex shrink>
                <v-select
                  name="bulk"
                  v-model="bulk"
                  :error-messages="errors.collect('bulk')"
                  data-vv-name="bulk"
                  :data-vv-as="$t('package.list.filters.bulk')"
                  :items="bulkOptions"
                  item-text="name"
                  item-value="value"
                  clearable
                  :label="$t('package.list.filters.bulk')"
                ></v-select>
              </v-flex>
              <v-flex shrink>
                <v-checkbox
                  v-model="displayWithoutDisabled"
                  :label="$t(`package.list.filters.without_deactivated`)"
                  :value="displayWithoutDisabled"
                  :error-messages="errors.collect('displayWithoutDisabled')"
                  data-vv-name="displayWithoutDisabled"
                  :data-vv-as="$t('package.list.filters.without_deactivated')"
                />
              </v-flex>
              <v-flex shrink>
                <button-primary type="submit" :text="$t('package.list.filters.submit')" :loading="working"></button-primary>
              </v-flex>
              <v-flex shrink>
                <button-primary class="package-form--btn-add" :loading="working" :text="$t('package.form.add')" @click="showModalAddPackage"></button-primary>
              </v-flex>
            </v-layout>
          </form>
        </v-flex>
      </v-layout>
    </v-card>

    <modal-action :show-modal="showAddPackageModal"> <add-package-action @close="closeModalAddPackage" @action="reloadPage"></add-package-action> </modal-action>

    <v-flex class="data-section"> <packages-list v-if="dataReady && packagesList" :items="packagesList"></packages-list> </v-flex>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, cypress, security } from '@/mixins';
import PackagesList from './PackagesList';
import AddPackageAction from './AddPackageAction';

export default {
  name: 'view-package-form',
  mixins: [actionErrorTrackable, security, cypress],
  components: { PackagesList, AddPackageAction },
  data() {
    return {
      dataReady: false,
      showAddPackageModal: false,
      displayWithoutDisabled: true,
      status: 'active',
      keyword: '',
      pack: '',
      logo: '',
      bulk: '',
      bulkOptions: [
        { name: this.$t('package.list.bulk.true'), value: 'true' },
        { name: this.$t('package.list.bulk.false'), value: 'false' },
      ],
    };
  },
  computed: {
    ...mapGetters('package', ['packagesList', 'listStatus', 'listKeyword', 'listPackage', 'listLogo', 'listBulk', 'pageNumber']),
  },
  async created() {
    await this.fetchPackages();
  },
  methods: {
    async submit() {
      console.log('submit');
      if (this.displayWithoutDisabled) {
        this.status = 'active';
      } else {
        this.status = '';
      }
      await this.fetchPackages();
    },
    async fetchPackages() {
      this.dataReady = false;
      await this.executeAction({ action: 'package/listPackages', success: this.success }, { status: this.status, keyword: this.keyword, pack: this.pack, logo: this.logo, bulk: this.bulk });
      this.dataReady = true;
    },
    showModalAddPackage() {
      this.showAddPackageModal = true;
    },
    closeModalAddPackage() {
      this.showAddPackageModal = false;
    },
    reloadPage() {
      this.showAddPackageModal = false;
    },
  },
};
</script>
